import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CiCircleQuestion } from "react-icons/ci";
import { appConstants } from "constants/app.constants";
import { BalancesTableProps } from './types';
import Table from 'react-bootstrap/Table';
import { BsInfoCircle } from "react-icons/bs";
import { PiCopyLight } from "react-icons/pi";
import { MdOutlineDone } from "react-icons/md";
import useCopyColumn from "hooks/useCopyColumn";

const BalancesTable: React.FC<BalancesTableProps> = ({ balanceTable, selectedCurrency, selectedComponentId, columns }) => {
    const { copied, handleCopyColumn } = useCopyColumn(balanceTable, 'BalanceTable');

    return (
        <Table striped bordered responsive>
            <thead>
            <tr>
                <th>Currency
                    <OverlayTrigger placement='top' overlay={<Tooltip>{copied ? 'Copied!': 'Copy column'}</Tooltip>}>
                            <span  className='copy-icon table-icon'>
                                {copied ? <MdOutlineDone /> : <PiCopyLight onClick={handleCopyColumn} /> }
                            </span>
                    </OverlayTrigger>
                </th>
                {columns.map((header: string) => (
                    <th key={header}>
                        {header}
                        {header !== appConstants.COLUMNS.BALANCE_TABLE_SUM && selectedComponentId === appConstants.COMPONENTS.DEFAULT && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{appConstants.MESSAGES.getBalanceHeaderColumnInfo(header)}</Tooltip>}
                            >
                                    <span className="table-icon">
                                        <BsInfoCircle />
                                    </span>
                            </OverlayTrigger>
                        )}
                    </th>
                ))}
                <th>TOTAL, {selectedCurrency}</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(balanceTable.table).map((currency) => (
                <tr key={currency}>
                    <td>
                        {currency}
                        {balanceTable.invalidRates && balanceTable.invalidRates.includes(currency) && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-${currency}`}>{appConstants.MESSAGES.ENGINEERING_TEAM_WORKING_ON} calculating the currency rate for {currency}</Tooltip>}
                            >
                                <span className="table-icon">
                                    <CiCircleQuestion />
                                </span>
                            </OverlayTrigger>
                        )}
                    </td>
                    {columns.map((header: string) => (
                        <td key={header}>
                            {balanceTable.table[currency][header]}
                        </td>
                    ))}
                    <td>
                        {balanceTable.totalColumn[currency]?.[selectedCurrency]}
                    </td>
                </tr>
            ))}
            <tr>
                <td className="table-total-row">TOTAL, {selectedCurrency}</td>
                {columns.map((header: string) => (
                    <td key={header}>
                        {balanceTable.totalRow[header]?.[selectedCurrency]}
                    </td>
                ))}
                <td>
                    {balanceTable.totalRow?.TOTAL[selectedCurrency]}
                </td>
            </tr>
            </tbody>
        </Table>
    );
};

export default BalancesTable;
