import { OptionType } from "pages/types/balancesTypes";

// ================================================================================================================
// Balance Columns
// ================================================================================================================
export const localStorageBalanceColumnsSet = (cols: string[]) => {
    localStorage.setItem('balances-columns', JSON.stringify(cols));
};

export const localStorageBalanceColumnsGet = () => {
    return localStorage.getItem('balances-columns');
};

export const localStorageBalanceColumnsRemove = () => {
    localStorage.removeItem('balances-columns');
};

// ================================================================================================================
// Balance Exchanges
// ================================================================================================================
export const localStorageDefaultExchangeSet = (exchange: OptionType) => {
    localStorage.setItem('selected-exchange', JSON.stringify(exchange));
};
