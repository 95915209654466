import React from "react";
import { Helmet } from "react-helmet-async";
import { appConstants } from "constants/app.constants";
import stringUtils from "utils/stringUtils";

const OverviewPage: React.FC = () => {
    const title = stringUtils.getHtmlTitle(appConstants.TITLES.OVERVIEW);

    return <div>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        Overview Page</div>;
};

export default OverviewPage;
