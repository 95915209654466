import { createContext } from 'react';

interface CurrencyContextType {
    selectedCurrency: string;
    setSelectedCurrency: (currency: string) => void;
}

const defaultValue: CurrencyContextType = {
    selectedCurrency: 'USDT',
    setSelectedCurrency: () => {},
};

const CurrencyContext = createContext<CurrencyContextType>(defaultValue);

export default CurrencyContext;
