// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker {
    position: relative;
}

.react-datepicker__input-container input {
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    width: 100%;
    padding: 0 5px;
    font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/components/datepicker.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,eAAe;AACnB","sourcesContent":[".datepicker {\n    position: relative;\n}\n\n.react-datepicker__input-container input {\n    border: 1px solid #d3d3d3;\n    border-radius: 2px;\n    width: 100%;\n    padding: 0 5px;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
