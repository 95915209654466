import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'http://api.qa.ccextrader.com/v1'
});

export const fakeServerAxiosClient = axios.create({
    baseURL: 'http://localhost:7123/v1/ccxtrader'
})

export default axiosClient;
