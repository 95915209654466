// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-icon {
    top: 11px;
    left: 10px;
    color: var(--body-color);
}

.edit-icon {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    border-radius: 50%;
    cursor: pointer;
}

.edit-image-icon {
    color: var(--white);
    font-size: 2rem;
}

.profile-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.calendar-icon {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/components/icons.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iCAAiC;IACjC,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,QAAQ;IACR,WAAW;IACX,gCAAgC;AACpC","sourcesContent":[".input-icon {\n    top: 11px;\n    left: 10px;\n    color: var(--body-color);\n}\n\n.edit-icon {\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0,0,0,0.5);\n    border-radius: 50%;\n    cursor: pointer;\n}\n\n.edit-image-icon {\n    color: var(--white);\n    font-size: 2rem;\n}\n\n.profile-icon {\n    width: 20px;\n    height: 20px;\n    cursor: pointer;\n}\n\n.calendar-icon {\n    position: absolute;\n    right: 0;\n    top: 50%;\n    z-index: 10;\n    transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
