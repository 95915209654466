import React from "react";
import PerformanceTable from "components/PerformanceTable";
import TableControlsPanel from "components/TableControlsPanel";
import { getPortfolioPerformance } from "api/apis";
import useTableConfigsData from "hooks/useTableConfigsData";
import Error from "components/Error";
import ExchangeError from "components/ExchangeError";
import { Helmet } from "react-helmet-async";
import Spinner from "react-bootstrap/Spinner";
import stringUtils from "utils/stringUtils";
import { appConstants } from "constants/app.constants";

const PortfolioPerformancePage: React.FC = () => {
    const {
        balanceTablePerformanceData,
        columns,
        selectedColumns,
        selectedComponentId,
        sortOption,
        hideSmallAssets,
        selectedCurrency,
        handleInputChange,
        handleCheckboxChange,
        handleSortOptionChange,
        handleHideSmallAssetsChange,
        exchangesOptions,
        error,
        loading,
        handleStartDateChange,
        startDate,
        handleEndDateChange,
        endDate,
        instant,
        toggleInstant
    } = useTableConfigsData(getPortfolioPerformance);

    const title = stringUtils.getHtmlTitle(appConstants.TITLES.PORTFOLIO_PERFORMANCE);

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {loading && (
                <div className='spinner-wrapper'>
                    <Spinner animation="border" role="status"/>
                </div>
            )}
            <Error error={error} />
            <ExchangeError balances={balanceTablePerformanceData} />
            <TableControlsPanel
                exchangesOptions={exchangesOptions}
                selectedComponentId={selectedComponentId}
                columns={columns}
                selectedColumns={selectedColumns}
                sortOption={sortOption}
                hideSmallAssets={hideSmallAssets}
                handleInputChange={handleInputChange}
                handleCheckboxChange={handleCheckboxChange}
                handleSortOptionChange={handleSortOptionChange}
                handleHideSmallAssetsChange={handleHideSmallAssetsChange}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                instant={instant}
                toggleInstant={toggleInstant}
                startDate={startDate}
                endDate={endDate}
                parentComponent='PortfolioPerformancePage'
            />
            {balanceTablePerformanceData &&  (
                <PerformanceTable
                    balanceTablePerformanceData={balanceTablePerformanceData}
                    selectedCurrency={selectedCurrency}
                    selectedComponentId={selectedComponentId?.value || ""}
                    columns={selectedColumns}
                />
            )}
        </>
    );
};

export default PortfolioPerformancePage;
