import { PiCurrencyDollar, PiCurrencyEur, PiCurrencyGbp, PiCurrencyJpy, PiCurrencyBtc } from "react-icons/pi";
import { TbCurrencyTugrik } from "react-icons/tb";

export const appConstants = {
    NAME: {
        LOWERCASE: 'ccextrader',
        CAMELCASE: 'ccexTrader',
        UPPERCASE: 'CCEXTRADER'
    },
    ACCOUNT: {
        HARDCODED: 'jbs7vbj97qnk8do2f7mc'
    },
    COMPONENTS: {
        DEFAULT: 'ACCOUNT'
    },
    COLUMNS: {
        BALANCE_TABLE_SUM: 'SUM'
    },
    TITLES: {
        TERMS_OF_SERVICES: 'Terms of Services',
        PRIVACY_POLICY: 'Privacy Policy',
        OVERVIEW: 'Overview',
        PORTFOLIO_INSTANT: 'Portfolio Instant',
        PORTFOLIO_PERFORMANCE: 'Portfolio Performance',
        SETTINGS: 'Settings',
        TRADE_BASIC: 'Trade Basic'
    },
    MAPPINGS: {
        Funding: ['BYBIT'],
        Spot: ['BINANCE'],
        Margin: ['BINANCE'],
        Futures: ['BINANCE'],
        Main: ['BYBIT', 'WHITEBIT'],
        Trade: ['WHITEBIT']
    },
    MESSAGES: {
        ENGINEERING_TEAM_WORKING_ON: 'Our engineering team is working on',
        getBalanceHeaderColumnInfo: function(key) {
            return `Balance is present on: ${appConstants.MAPPINGS[key]?.join(', ')}`;
        }
    },
    VISIBILITY: {
        // no flags yet
    },
    CURRENCY_OPTIONS: [
        { value: 'USDT', label: 'USDT', icon: <TbCurrencyTugrik /> },
        { value: 'USD', label: 'USD', icon: <PiCurrencyDollar /> },
        { value: 'EUR', label: 'EUR', icon: <PiCurrencyEur /> },
        { value: 'GBP', label: 'GBP', icon: <PiCurrencyGbp /> },
        { value: 'JPY', label: 'JPY', icon: <PiCurrencyJpy /> },
        { value: 'BTC', label: 'BTC', icon: <PiCurrencyBtc /> }
    ],
    SORTING_OPTIONS: {
        DEFAULT_SORT_OPTION: 'CURRENCY, ASC',
        BALANCE_TABLE: [
            { key: 'CURRENCY, ASC', prefix: 'Currency', suffix: '(A → Z)' },
            { key: 'CURRENCY, DESC', prefix: 'Currency', suffix: '(Z → A)' },
            { key: 'WORTH, ASC', prefix: 'Worth', suffix: '(Low → High)' },
            { key: 'WORTH, DESC', prefix: 'Worth', suffix: '(High → Low)' }
        ]
    }
};
