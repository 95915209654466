import React, { useState, useRef, useEffect } from 'react';
import { LiaEditSolid } from "react-icons/lia";
import { TfiEmail } from "react-icons/tfi";
import { IoPersonOutline } from "react-icons/io5";

export const ProfilePage: React.FC = () => {
    const [name, setName] = useState<string>('Charles Hall');
    const [email, setEmail] = useState<string>('charles.hall@gmail.com');

    const [editedName, setEditedName] = useState<string>(name);

    const nameInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const savedName = localStorage.getItem('name');

        if (savedName) {
            setName(savedName);
            setEditedName(savedName);
        }
    }, []);

    useEffect(() => {
            localStorage.setItem('name', name);
    }, [name]);

    const handleEditSaveClick = () => {
            setName(editedName);
    };

    return (
        <div className="profile container-fluid p-0">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0">Profile Details</h5>
                </div>
                <div className="card-body h-100">
                    <div className="d-flex gap-5">
                        <div className="w-50 position-relative">
                            <TfiEmail className="input-icon position-absolute"/>
                            <input
                                disabled
                                type="text"
                                className="input form-control"
                                placeholder="Email"
                                value={email}
                            />
                        </div>
                        <div className="w-50 position-relative">
                            <IoPersonOutline className="input-icon position-absolute"/>
                            <input
                                type="text"
                                className="input form-control"
                                placeholder="Name"
                                value={editedName}
                                onChange={(e) => setEditedName(e.target.value)}
                                ref={nameInputRef}
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        <button
                            className="button profile-button btn btn-primary d-flex align-items-center"
                            onClick={handleEditSaveClick}
                        >
                            <LiaEditSolid/>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
