// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings__key {
    font-size: 14px;
    color: var(--gray-500);
}

.settings__key-type {
    border-bottom: 1px solid var(--gray-200);
}

.settings__input-label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 12px;
    color: var(--gray-600);
}

.settings__exchange-name {
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid var(--gray-300);
    background-color: var(--gray-100);
}

.settings__empty-state {
    border-top: 1px solid var(--gray-300);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/settings.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,wCAAwC;IACxC,iCAAiC;AACrC;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".settings__key {\n    font-size: 14px;\n    color: var(--gray-500);\n}\n\n.settings__key-type {\n    border-bottom: 1px solid var(--gray-200);\n}\n\n.settings__input-label {\n    display: inline-block;\n    margin-bottom: 5px;\n    font-size: 12px;\n    color: var(--gray-600);\n}\n\n.settings__exchange-name {\n    font-size: 14px;\n    font-weight: 500;\n    border-bottom: 1px solid var(--gray-300);\n    background-color: var(--gray-100);\n}\n\n.settings__empty-state {\n    border-top: 1px solid var(--gray-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
