import { useEffect, useState } from "react";

const generateMockData = (type: 'bid' | 'ask', count: number) => {
    return Array.from({ length: count }, (_, i) => ({
        price: Math.random() * 100000,
        amount: Math.random() * 10,
        total: Math.random() * 100,
    }));
};

const OrderBook = () => {
    const [bids, setBids] = useState(generateMockData('bid', 10));
    const [asks, setAsks] = useState(generateMockData('ask', 10));

    useEffect(() => {
        const interval = setInterval(() => {
            setBids(generateMockData('bid', 10));
            setAsks(generateMockData('ask', 10));
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="orderbook">
            <div className="p-2">
                <table className="table table-sm table-striped mb-0">
                    <thead>
                    <tr>
                        <th className="orderbook__column-title">Price</th>
                        <th className="orderbook__column-title">Amount</th>
                        <th className="orderbook__column-title">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bids.map((bid, index) => (
                        <tr key={index}>
                            <td className="text-danger orderbook__cell-text">{bid.price.toFixed(2)}</td>
                            <td className="orderbook__cell-text">{bid.amount.toFixed(2)}</td>
                            <td className="orderbook__cell-text">{bid.total.toFixed(2)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="p-2">
                <table className="table table-sm table-striped mb-0">
                    <thead>
                    <tr>
                        <th className="orderbook__column-title">Price</th>
                        <th className="orderbook__column-title">Amount</th>
                        <th className="orderbook__column-title">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {asks.map((ask, index) => (
                        <tr key={index}>
                            <td className="text-success orderbook__cell-text">{ask.price.toFixed(2)}</td>
                            <td className="orderbook__cell-text">{ask.amount.toFixed(2)}</td>
                            <td className="orderbook__cell-text">{ask.total.toFixed(2)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderBook;
