import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "components/Layout";
import PortfolioInstantPage from "./pages/PortfolioInstantPage";
import OverviewPage from "pages/OverviewPage";
import { ProfilePage } from "pages/ProfilePage";
import SettingsPage from "pages/SettingsPage";
import TermsOfServicesPage from "pages/TermsOfServicesPage";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import TradeBasicPage from "pages/TradeBasicPage";
import CurrencyContext from "./contexts/CurrencyContext";
import PortfolioPerformancePage from "./pages/PortfolioPerformancePage";

function App() {
    const [selectedCurrency, setSelectedCurrency] = useState("USDT");

    return (
        <CurrencyContext.Provider value={{ selectedCurrency, setSelectedCurrency }}>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/terms-of-services" element={<TermsOfServicesPage />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        {/*TODO [MeAn] [YYL] anonymous/authenticated check on "/" page */}
                        <Route path="/" element={<OverviewPage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/portfolio/instant" element={<PortfolioInstantPage />} />
                        <Route path="/portfolio/performance" element={<PortfolioPerformancePage />} />
                        <Route path="/trade/basic" element={<TradeBasicPage />} />
                    </Routes>
                </Layout>
            </Router>
        </CurrencyContext.Provider>
    );
}

export default App;
