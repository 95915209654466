import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdOutlineDone } from "react-icons/md";
import { PiCopyLight } from "react-icons/pi";
import { appConstants } from "constants/app.constants";
import { BsInfoCircle } from "react-icons/bs";
import { CiCircleQuestion } from "react-icons/ci";
import Table from "react-bootstrap/Table";
import React from "react";
import { BalanceBoxPerformanceJSON, BalancePerformanceTableProps } from "components/types";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import useCopyColumn from "hooks/useCopyColumn";
import { IoIosArrowRoundForward } from "react-icons/io";
import { LiaEqualsSolid } from "react-icons/lia";

const PerformanceTable: React.FC<BalancePerformanceTableProps> = ({
                                                                      balanceTablePerformanceData,
                                                                      selectedCurrency,
                                                                      selectedComponentId,
                                                                      columns,
                                                                  }) => {
    const {copied, handleCopyColumn} = useCopyColumn(balanceTablePerformanceData, 'PerformanceTable');

    const renderCellValue = (value: any, cellName: string) => {
        const cell = value as BalanceBoxPerformanceJSON;
        return (
            <div className="performance-table__cell">
                <Col xs={6}>
                    {cell.signedValueIS},
                </Col>
                {cellName === "Total" ? (
                    <span className={cell.performance === "UP" ? "green balance-percentage" : "red balance-percentage"}>
                        {cell.performance === "UP" ? <IoIosArrowRoundUp /> : <IoIosArrowRoundDown />}
                        {cell.percentage.percentage}%
                    </span>
                ) : (
                    <span className="balance-percentage">
                        {cell.performance === "UP" ? <IoIosArrowRoundUp /> : <IoIosArrowRoundDown />}
                        {cell.percentage.percentage}%
                    </span>
                )}
            </div>
        );
    };

    const renderTotalCellValue = (value: any) => {
        const cell = value as BalanceBoxPerformanceJSON;
        return (
            <div className="performance-table__cell">
                <Col xs={6}>{cell.signedValueIS}, </Col>
                <span className={cell.performance === "UP" ? "green balance-percentage" : "red balance-percentage"}>
                    {cell.performance === "UP" ? <IoIosArrowRoundUp /> : <IoIosArrowRoundDown />}
                    {cell.percentage.percentage}%
                </span>
            </div>
        );
    };

    const addClass = (performance: string) => {
        if (performance === 'UP') {
            return 'color-green performance-table__cell-details-value'
        }
        if (performance === 'DOWN') {
            return 'color-red performance-table__cell-details-value'
        }
        return 'performance-table__cell-details-value'
    }

    const defineArrow = (performance: string) => {
        if (performance === 'UP') {
            return <IoIosArrowRoundUp />
        }

        if (performance === 'DOWN') {
            return <IoIosArrowRoundDown />
        }
        return ''
    }

    return (
        <>
            <div className='performance-table__total-balance'>
                <span>Total: {balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency].signedValueIS}</span>
                <span
                    className={addClass(balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency].performance)}>
                    {balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency].performance === "UP" ? <IoIosArrowRoundUp /> :
                        <IoIosArrowRoundDown/>}
                    {balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency].percentage.percentage}
                </span>
            </div>
            <Table striped bordered responsive hover className="performance-table">
                <thead>
                <tr>
                    <th>
                        Currency
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{copied ? "Copied!" : "Copy column"}</Tooltip>}>
                                <span className="copy-icon table-icon">
                                    {copied ? <MdOutlineDone /> : <PiCopyLight onClick={handleCopyColumn} />}
                                </span>
                        </OverlayTrigger>
                    </th>
                    {columns.map((header: string) => (
                        <th key={header}>
                            {header}
                            {header !== appConstants.COLUMNS.BALANCE_TABLE_SUM &&
                                selectedComponentId === appConstants.COMPONENTS.DEFAULT && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>{appConstants.MESSAGES.getBalanceHeaderColumnInfo(header)}</Tooltip>
                                        }>
                                            <span className="table-icon">
                                                <BsInfoCircle />
                                            </span>
                                    </OverlayTrigger>
                                )}
                        </th>
                    ))}
                    <th>TOTAL, {selectedCurrency}</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(balanceTablePerformanceData.table).map((currency) => (
                    <tr key={currency}>
                        <td>
                            {currency}
                            {balanceTablePerformanceData.invalidRates &&
                                balanceTablePerformanceData.invalidRates.includes(currency) && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-${currency}`}>
                                                {appConstants.MESSAGES.ENGINEERING_TEAM_WORKING_ON} calculating the
                                                currency rate for {currency}
                                            </Tooltip>
                                        }>
                                            <span className="table-icon">
                                                <CiCircleQuestion />
                                            </span>
                                    </OverlayTrigger>
                                )}
                        </td>
                        {columns.map((header: string) => {
                            return (
                                <td key={header}>
                                    {renderCellValue(balanceTablePerformanceData.table[currency][header], "")}
                                    <div className="performance-table__cell-details">
                                        <span className='performance-table__cell-details-value'>
                                            {balanceTablePerformanceData.table[currency][header]?.valueIS}
                                        </span>
                                        <span className='performance-table__cell-details-value'>
                                            <IoIosArrowRoundForward className='mx-1'/>
                                            {balanceTablePerformanceData.table[currency][header]?.signedValueIS}
                                        </span>
                                        <span className={addClass(balanceTablePerformanceData.table[currency][header]?.performance)}>
                                             <LiaEqualsSolid className='mx-1'/>
                                            {defineArrow(balanceTablePerformanceData.table[currency][header]?.performance)}
                                            {balanceTablePerformanceData.table[currency][header]?.percentage.value}
                                        </span>
                                    </div>
                                </td>
                            );
                        })}
                        <td>
                            {renderTotalCellValue(balanceTablePerformanceData.totalColumn[currency]?.[selectedCurrency])}
                            <div className="performance-table__cell-details">
                                <span className='performance-table__cell-details-value'>
                                    {balanceTablePerformanceData.totalColumn[currency]?.[selectedCurrency]?.valueIS}
                                </span>
                                <span className='performance-table__cell-details-value'>
                                     <IoIosArrowRoundForward className='mx-1'/>
                                     {balanceTablePerformanceData.totalColumn[currency]?.[selectedCurrency]?.signedValueIS}
                                     <LiaEqualsSolid className='mx-1'/>
                                </span>
                                <span
                                    className={addClass(balanceTablePerformanceData.totalColumn[currency]?.[selectedCurrency]?.performance)}>
                                    {balanceTablePerformanceData.totalColumn[currency]?.[selectedCurrency]?.percentage.value}
                                </span>
                            </div>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td className="table-total-row">TOTAL, {selectedCurrency}</td>
                    {columns.map((header: string) => (
                        <td
                            key={header}>
                            {renderCellValue(balanceTablePerformanceData.totalRow[header]?.[selectedCurrency], "Total")}
                            <div className="performance-table__cell-details">
                                <span className='performance-table__cell-details-value'>
                                    {balanceTablePerformanceData.totalRow[header]?.[selectedCurrency]?.valueIS}
                                </span>
                                <span className='performance-table__cell-details-value'>
                                     <IoIosArrowRoundForward className='mx-1'/>
                                    {balanceTablePerformanceData.totalRow[header]?.[selectedCurrency]?.signedValueIS}
                                    <LiaEqualsSolid className='mx-1'/>
                                </span>
                                <span
                                    className={addClass(balanceTablePerformanceData.totalRow[header]?.[selectedCurrency]?.performance)}>
                                    {defineArrow(balanceTablePerformanceData.totalRow[header]?.[selectedCurrency]?.performance)}
                                    {balanceTablePerformanceData.totalRow[header]?.[selectedCurrency]?.percentage.value}
                                </span>
                            </div>
                        </td>
                    ))}
                    <td>
                        {renderCellValue(balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency], "Total")}
                        <div className="performance-table__cell-details">
                            <span className='performance-table__cell-details-value'>
                                <IoIosArrowRoundForward className='mx-1'/>
                                {balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency]?.valueIS}
                            </span>
                            <span className='performance-table__cell-details-value'>
                                {balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency]?.signedValueIS}
                                <LiaEqualsSolid className='mx-1'/>
                            </span>
                            <span className={addClass(balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency]?.performance)}>
                                {balanceTablePerformanceData.totalRow?.TOTAL[selectedCurrency]?.percentage.value}
                            </span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    );
};

export default PerformanceTable;
