import React, {useState} from "react";
import { GoFilter } from "react-icons/go";
import { BiSortAlt2 } from "react-icons/bi";
import BalanceTableSortingOptions from "components/BalanceTableSortingOptions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendar } from "react-icons/ci";

interface TableControlsPanelProps {
    exchangesOptions: { value: string; label: string }[];
    selectedComponentId: { value: string; label: string } | null;
    columns: string[];
    selectedColumns: string[];
    sortOption: string;
    hideSmallAssets: boolean;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCheckboxChange: (column: string, isChecked: boolean) => void;
    handleSortOptionChange: (newSortOption: string) => void;
    handleHideSmallAssetsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleStartDateChange?: (value: any) => void;
    handleEndDateChange?: (value: any) => void;
    instant?: boolean;
    toggleInstant?: () => void;
    startDate?: Date | null;
    endDate?: Date | null;
    parentComponent?: string;
}

const TableControlsPanel: React.FC<TableControlsPanelProps> = ({
                                                       exchangesOptions,
                                                       selectedComponentId,
                                                       columns,
                                                       selectedColumns,
                                                       sortOption,
                                                       hideSmallAssets,
                                                       handleInputChange,
                                                       handleCheckboxChange,
                                                       handleSortOptionChange,
                                                       handleHideSmallAssetsChange,
                                                       handleStartDateChange,
                                                       handleEndDateChange,
                                                       instant,
                                                       toggleInstant,
                                                       startDate,
                                                       endDate,
                                                       parentComponent
                                                   }) => {
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [sortDropdown, setSortDropdown] = useState(false);
    const [exchangeDropdown, setExchangeDropdown] = useState(false);

    const toggleDropdown = (key: string) => {
        switch (key) {
            case 'Exchanges':
                setExchangeDropdown(!exchangeDropdown);
                break;
            case 'Filter':
                setFilterDropdown(!filterDropdown);
                break;
            case 'Sort':
                setSortDropdown(!sortDropdown);
                break;
        }
    }

    return (
        <div className="table-configs__container">
            <div
                onMouseEnter={() => toggleDropdown("Exchanges")}
                onMouseLeave={() => toggleDropdown("Exchanges")}
                className="table-configs__dropdown-wrapper d-flex align-items-center position-relative"
            >
                <span>{selectedComponentId?.label}</span>
                {exchangeDropdown && (
                    <div className="table-configs__exchanges">
                        {exchangesOptions.map((option, index) => (
                            <label
                                key={index}
                                className="table-configs__label d-flex gap-2"
                            >
                                <input
                                    onChange={handleInputChange}
                                    checked={selectedComponentId?.value === option.value}
                                    type="radio"
                                    name="options"
                                    value={option.value}
                                />
                                {option.label}
                            </label>
                        ))}
                    </div>
                )}
            </div>
            <div
                onMouseEnter={() => toggleDropdown("Filter")}
                onMouseLeave={() => toggleDropdown("Filter")}
                className="table-configs__dropdown-wrapper d-flex align-items-center position-relative"
            >
                <span className="d-flex align-items-center gap-1">
                    <GoFilter/> Filter
                </span>
                {filterDropdown && (
                    <div className="table-configs__exchanges">
                        {columns.map(
                            (column, index) =>
                                column !== "SUM" && (
                                    <div
                                        key={index}
                                        className="table-container__input-wrapper"
                                    >
                                        <input
                                            id={`columnInput-${index}`}
                                            checked={selectedColumns.includes(column)}
                                            onChange={(e) =>
                                                handleCheckboxChange(column, e.target.checked)
                                            }
                                            type="checkbox"
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                        <label
                                            className="px-1"
                                            onClick={(e) => e.stopPropagation()}
                                            htmlFor={`columnInput-${index}`}
                                        >
                                            {column}
                                        </label>
                                    </div>
                                )
                        )}
                    </div>
                )}
            </div>
            <div
                onMouseEnter={() => toggleDropdown("Sort")}
                onMouseLeave={() => toggleDropdown("Sort")}
                className="table-configs__dropdown-wrapper d-flex align-items-center position-relative"
            >
                <span className="d-flex align-items-center gap-1">
                    <BiSortAlt2/> Sort
                </span>
                {sortDropdown && (
                    <BalanceTableSortingOptions
                        sortOption={sortOption}
                        handleSortOptionChange={handleSortOptionChange}
                    />
                )}
            </div>
            <label className="d-flex align-items-center">
                <input
                    className="me-1"
                    onChange={handleHideSmallAssetsChange}
                    checked={hideSmallAssets}
                    type="checkbox"
                />
                Hide assets ﹤ 1 USDT
            </label>
            {parentComponent === 'PortfolioPerformancePage' &&
            <div className="date-picker-wrapper d-flex align-items-center gap-3">
                <div className="datepicker">
                    <CiCalendar className='calendar-icon'/>
                    <DatePicker placeholderText='Select start date' selected={startDate}
                                onChange={handleStartDateChange}/>
                </div>
                <div className="datepicker">
                    <CiCalendar className='calendar-icon'/>
                    <DatePicker placeholderText='Select end date' selected={endDate}
                                onChange={handleEndDateChange} disabled={instant} className={instant ? 'disabled' : ''} />
                </div>
                <label className='d-flex align-items-center gap-1'>
                    <input checked={instant} onChange={toggleInstant} type="checkbox"/>
                    Instant
                </label>
            </div>
            }
        </div>
    );
};

export default TableControlsPanel;
