import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { HelmetProvider } from 'react-helmet-async';
import 'react-bootstrap/dist/react-bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';
import App from './App';
import {browserTracingIntegration} from "@sentry/react";

Sentry.init({
    dsn: "https://015af382b96d9690635a9d70185e8709@o4508257211383808.ingest.de.sentry.io/4508257214267472",
    integrations: browserTracingIntegration(),
    tracesSampleRate: 1.0
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
        <App />
    </HelmetProvider>
);
