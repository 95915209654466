// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trade-basic__order-form {
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/trade-basic.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;AACrD","sourcesContent":[".trade-basic__order-form {\n    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
