import React from "react";
import { MdErrorOutline } from "react-icons/md";

interface ErrorProps {
    error: string;
}

const Error = ({ error }: ErrorProps) => {
    return (
        <>
        {error &&
            <p className='error d-flex align-items-center gap-1'>
                <MdErrorOutline/>{error}
            </p>
        }
        </>
    )
}

export default Error;
