import React from "react";
import Navbar from "./Navbar";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div className="main-layout">
            <div className="main-content">
                <header className='navbar navbar-expand navbar-light navbar-bg w-100'>
                    <Navbar />
                </header>
                <main className='page-content'>
                    {children}
                </main>
            </div>
        </div>
    );
};

export default Layout;
