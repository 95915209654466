import { useState } from "react";
import { BalanceTable, PerformanceTable } from "components/types";

type ParentComponentType = "PerformanceTable" | "BalanceTable";

const useCopyColumn = (
    data: PerformanceTable | BalanceTable,
    parentComponent: ParentComponentType
) => {
    const [copied, setCopied] = useState(false);

    const handleCopyColumn = () => {
        let formattedText = "";

        if (parentComponent === "PerformanceTable") {
            formattedText = Object.keys((data as PerformanceTable).table).join("\n");
        } else if (parentComponent === "BalanceTable") {
            formattedText = Object.keys((data as BalanceTable).table).join("\n");
        }

        navigator.clipboard.writeText(formattedText);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return {
        copied,
        handleCopyColumn,
    };
};

export default useCopyColumn;
