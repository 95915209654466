import { useState, useEffect, useContext } from 'react';
import CurrencyContext from "contexts/CurrencyContext";
import { OptionType } from "pages/types/balancesTypes";
import {BalanceTable, PerformanceTable} from "components/types";
import { appConstants } from "constants/app.constants";
import {
    localStorageBalanceColumnsGet,
    localStorageBalanceColumnsRemove,
    localStorageBalanceColumnsSet,
    localStorageDefaultExchangeSet
} from "services/localStorageService";
import * as Sentry from "@sentry/react";

function useTableConfigsData (apiFunction: any) {
    const [balanceTableData, setBalanceTableData] = useState<{ [key: string]: BalanceTable }>({});
    const [selectedExchangePerformanceData, setSelectedExchangePerformanceData] = useState<{ [key: string]: PerformanceTable }>({});
    const [selectedExchangeBalanceData, setSelectedExchangeBalanceData] = useState<BalanceTable | null>(null);
    const [balanceTablePerformanceData, setBalanceTablePerformanceData] = useState<PerformanceTable | null>(null);
    const [loading, setLoading] = useState(true);
    const [exchanges, setExchanges] = useState<string[]>([]);
    const [columns, setColumns] = useState<string[]>([]);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [selectedComponentId, setSelectedComponentId] = useState<OptionType | null>({
        value: appConstants.COMPONENTS.DEFAULT,
        label: appConstants.COMPONENTS.DEFAULT,
    });
    const [sortOption, setSortOption] = useState<string>(
        appConstants.SORTING_OPTIONS.DEFAULT_SORT_OPTION
    );
    const [hideSmallAssets, setHideSmallAssets] = useState(false);
    const [instant, setInstant] = useState(false);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [error, setError] = useState('');

    const { selectedCurrency } = useContext(CurrencyContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                localStorageBalanceColumnsRemove();

                const portfolioBalance = await apiFunction({
                    sortOption: sortOption || appConstants.SORTING_OPTIONS.DEFAULT_SORT_OPTION,
                    hideAssetsLessThanOneUSDT: hideSmallAssets,
                });

                setExchanges(Object.keys(portfolioBalance.values));
                setBalanceTableData(portfolioBalance.values);
                setSelectedExchangePerformanceData(portfolioBalance.values);

                if (!selectedComponentId?.value) {
                    const defaultComponentOption = appConstants.COMPONENTS.DEFAULT;
                    const defaultColumns =
                        portfolioBalance.values[defaultComponentOption]?.headers || [];

                    setSelectedComponentId({
                        value: defaultComponentOption,
                        label: defaultComponentOption,
                    });
                    setSelectedExchangeBalanceData(portfolioBalance.values[defaultComponentOption]);
                    setBalanceTablePerformanceData(portfolioBalance.values[defaultComponentOption]);
                    setColumns(defaultColumns);
                    setSelectedColumns(defaultColumns);

                    localStorageBalanceColumnsSet(defaultColumns);
                } else {
                    const selectedData = portfolioBalance.values[selectedComponentId.value];
                    if (selectedData) {
                        setSelectedExchangeBalanceData(selectedData);
                        setBalanceTablePerformanceData(selectedData);
                        setColumns(selectedData.headers);

                        const savedColumns = JSON.parse(localStorageBalanceColumnsGet() || '[]');
                        const updatedColumns =
                            savedColumns.length > 0 ? savedColumns : selectedData.headers;

                        setSelectedColumns(updatedColumns);
                        localStorageBalanceColumnsSet(updatedColumns);
                    }
                }
                setLoading(false);
            } catch (error: any) {
                Sentry.captureException(error);
                setError(error.message);
                setLoading(false);
            }
        };
        fetchData();
    }, [apiFunction, sortOption, hideSmallAssets]);

    useEffect(() => {
        if (selectedComponentId && balanceTableData) {
            const selectedData = balanceTableData[selectedComponentId.value];
            if (selectedData) {
                setColumns(selectedData.headers);

                const savedColumns = JSON.parse(localStorageBalanceColumnsGet() || '[]');
                const updatedColumns =
                    savedColumns.length > 0 ? savedColumns : selectedData.headers;

                setSelectedColumns(updatedColumns);
                localStorageBalanceColumnsSet(updatedColumns);
            } else {
                setColumns([]);
                setSelectedColumns([]);
            }
        }
    }, [selectedComponentId, balanceTableData]);

    const handleStartDateChange = (value: any) => {
        setStartDate(value);
    };

    const handleEndDateChange = (value: any) => {
        setEndDate(value);
    };

    const toggleInstant = () => {
        setInstant(!instant)
    }

    const handleComponentIdChange = (selectedOption: OptionType | null) => {
        setSelectedComponentId(selectedOption);

        if (selectedOption && balanceTableData && selectedExchangePerformanceData) {
            const selectedData = balanceTableData[selectedOption.value];
            const selectedPerformanceData = selectedExchangePerformanceData[selectedOption.value];
            if (selectedData) {
                setSelectedExchangeBalanceData(selectedData);
                setBalanceTablePerformanceData(selectedPerformanceData);

                const newColumns = selectedData.headers;
                setColumns(newColumns);
                setSelectedColumns(newColumns);

                localStorageDefaultExchangeSet(selectedOption);
                localStorageBalanceColumnsSet(newColumns);
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedOption =
            exchangesOptions.find((option) => option.value === event.target.value) || null;
        handleComponentIdChange(selectedOption);
    };

    const handleCheckboxChange = (column: string, isChecked: boolean) => {
        setSelectedColumns((prev) => {
            if (isChecked) {
                return columns.filter((col) => prev.includes(col) || col === column);
            } else {
                return prev.filter((col) => col !== column);
            }
        });
    };

    const handleSortOptionChange = (newSortOption: string) => {
        setSortOption(newSortOption);
    };

    const handleHideSmallAssetsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHideSmallAssets(e.target.checked);
    };

    const exchangesOptions: OptionType[] = exchanges.map((exchange) => ({
        value: exchange,
        label: exchange,
    }));

    return {
        balanceTableData,
        selectedExchangeBalanceData,
        loading,
        columns,
        selectedColumns,
        selectedComponentId,
        sortOption,
        hideSmallAssets,
        error,
        selectedCurrency,
        handleComponentIdChange,
        handleInputChange,
        handleCheckboxChange,
        handleSortOptionChange,
        handleHideSmallAssetsChange,
        exchangesOptions,
        balanceTablePerformanceData,
        handleStartDateChange,
        startDate,
        handleEndDateChange,
        endDate,
        instant,
        toggleInstant
    };
}

export default useTableConfigsData;
