import React from 'react';
import { appConstants } from "constants/app.constants";

const BalanceTableSortingOptions = ({ sortOption, handleSortOptionChange }: { sortOption: string; handleSortOptionChange: (value: string) => void }) => {
    return (
        <div className="table-configs__exchanges d-flex flex-column">
            {appConstants.SORTING_OPTIONS.BALANCE_TABLE.map(({ key, prefix, suffix }, index) => (
                <label key={key} className={'table-configs__label'}>
                    <input
                        id={`sort-option-${index}`}
                        className='me-1'
                        type="radio"
                        name="sort"
                        value={key}
                        checked={sortOption === key}
                        onChange={(e) => handleSortOptionChange(e.target.value)}
                    />
                    {prefix}<span className='sort-label-description'>{suffix}</span>
                </label>
            ))}
        </div>
    );
};

export default BalanceTableSortingOptions;
