// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
    margin-bottom: 5px;
}

label {
    cursor: pointer;
}

.input {
    padding-left: 30px;
}

.file-input {
    display: none;
}

.form-control::placeholder {
    color: var(--gray-600);
}

.filter-input {
    padding-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/components/form.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".label {\n    margin-bottom: 5px;\n}\n\nlabel {\n    cursor: pointer;\n}\n\n.input {\n    padding-left: 30px;\n}\n\n.file-input {\n    display: none;\n}\n\n.form-control::placeholder {\n    color: var(--gray-600);\n}\n\n.filter-input {\n    padding-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
