// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select {
    min-width: max-content;
    margin-right: 20px;
    height: 30px;
    font-size: 14px;
}

.select .react-select__control {
    height: 30px;
    min-height: 30px;
    line-height: 1;
}

.select .react-select__value-container {
    padding: 0 3px 0 0;
}

.react-select__input-container {
    margin: 0;
    padding: 0;
}

.select .react-select__menu {
    width: max-content;
    z-index: 1000;
}

.select .react-select__indicator {
    display: flex;
    align-items: center;
    padding: 0;
    width: 15px;
    height: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/components/select.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,YAAY;AAChB","sourcesContent":[".select {\n    min-width: max-content;\n    margin-right: 20px;\n    height: 30px;\n    font-size: 14px;\n}\n\n.select .react-select__control {\n    height: 30px;\n    min-height: 30px;\n    line-height: 1;\n}\n\n.select .react-select__value-container {\n    padding: 0 3px 0 0;\n}\n\n.react-select__input-container {\n    margin: 0;\n    padding: 0;\n}\n\n.select .react-select__menu {\n    width: max-content;\n    z-index: 1000;\n}\n\n.select .react-select__indicator {\n    display: flex;\n    align-items: center;\n    padding: 0;\n    width: 15px;\n    height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
