import React from "react";
import { Accordion } from "react-bootstrap";

interface Error {
    errors: string[];
}

type Balances = Error | null;

interface BalancesProps {
    balances?: Balances;
}

const ExchangeError: React.FC<BalancesProps> = ({ balances }) => {
    return (
        <>
            {balances?.errors && balances.errors.length > 0 && (
                <Accordion className="mb-2">
                    <Accordion.Item className="balances__accordion-item" eventKey="0">
                        <Accordion.Header>ERRORS</Accordion.Header>
                        <Accordion.Body>
                            {balances?.errors.map((exception, index) => (
                                <div key={index}>{exception}</div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )}
        </>
    );
};

export default ExchangeError;
