// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reload-btn {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
}

.reload-btn-wrapper {
    height: 25px;
}

.reload-rates {
    margin-right: 20px;
}

.button {
    background-color: var(--black);
    border: none;
    transition: all 250ms ease-in-out;
}

.button:hover {
    background-color: var(--dark);
    transform: scale(1.05);
    border: none;
}

.profile-button {
    margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/components/buttons.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,iCAAiC;AACrC;;AAEA;IACI,6BAA6B;IAC7B,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".reload-btn {\n    width: 20px;\n    height: 20px;\n    margin-left: 5px;\n    cursor: pointer;\n}\n\n.reload-btn-wrapper {\n    height: 25px;\n}\n\n.reload-rates {\n    margin-right: 20px;\n}\n\n.button {\n    background-color: var(--black);\n    border: none;\n    transition: all 250ms ease-in-out;\n}\n\n.button:hover {\n    background-color: var(--dark);\n    transform: scale(1.05);\n    border: none;\n}\n\n.profile-button {\n    margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
