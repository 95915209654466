// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
    gap: 30px;
}

.card {
    margin-bottom: 24px;
    box-shadow: 0 0 0.875rem 0 rgba(0, 0, 0, 0.05);
}

.card-header {
    background-color: #fff;
    border-bottom: 0 solid transparent;
    margin-bottom: 0;
    padding: 1rem 1.25rem;
}

.card-title {
    font-size: 0.925rem;
    font-weight: 600;
    color: #7a8e96;
}

.card-subtitle {
    font-weight: 400;
}

.card-table {
    margin-bottom: 0;
}

.card-table tr td:first-child,
.card-table tr th:first-child {
    padding-left: 1.25rem;
}

.card-table tr td:last-child,
.card-table tr th:last-child {
    padding-right: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
    width: 100%;
    height: auto;
}

@media all and (-ms-high-contrast: none) {
    .card-img,
    .card-img-top,
    .card-img-bottom {
        height: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/profile.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,8CAA8C;AAClD;;AAEA;IACI,sBAAsB;IACtB,kCAAkC;IAClC,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;AACzB;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;;;IAGI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;;;QAGI,YAAY;IAChB;AACJ","sourcesContent":[".profile {\n    gap: 30px;\n}\n\n.card {\n    margin-bottom: 24px;\n    box-shadow: 0 0 0.875rem 0 rgba(0, 0, 0, 0.05);\n}\n\n.card-header {\n    background-color: #fff;\n    border-bottom: 0 solid transparent;\n    margin-bottom: 0;\n    padding: 1rem 1.25rem;\n}\n\n.card-title {\n    font-size: 0.925rem;\n    font-weight: 600;\n    color: #7a8e96;\n}\n\n.card-subtitle {\n    font-weight: 400;\n}\n\n.card-table {\n    margin-bottom: 0;\n}\n\n.card-table tr td:first-child,\n.card-table tr th:first-child {\n    padding-left: 1.25rem;\n}\n\n.card-table tr td:last-child,\n.card-table tr th:last-child {\n    padding-right: 1.25rem;\n}\n\n.card-img,\n.card-img-top,\n.card-img-bottom {\n    width: 100%;\n    height: auto;\n}\n\n@media all and (-ms-high-contrast: none) {\n    .card-img,\n    .card-img-top,\n    .card-img-bottom {\n        height: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
